import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Chart from 'react-apexcharts'

const isEmpty = (a) => {
  return a === null || a === undefined
}

export default function Charts(props) {
  const [chart, setChart] = useState({
    chart: {
      type: 'bar',
      //   height: 350,
      stacked: true,
      stackType: '100%',
    },
    colors: ['#00ff00', '#00e600', '#00cc00', '#009900', '#006600', '#003300'],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: 'Rating Distribution',
    },
    xaxis: {
      categories: [],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + ' ratings'
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  })

  const [series, setSeries] = useState([])

  useEffect(() => {
    // first getting all the months, and counting the feedback in the month
    const months = {}
    const series = {
      rate0: 0,
      rate1: 0,
      rate2: 0,
      rate3: 0,
      rate4: 0,
      rate5: 0,
    }
    for (let index = 0; index < props.historyKeys.length; index++) {
      const element = props.historyKeys[index]
      const satisfaction =
        isEmpty(props.history[element].guifeedback) ||
        props.history[element].user !== props.userFilter
          ? null
          : props.history[element].guifeedback.satisfaction
      if (!isEmpty(satisfaction) && satisfaction !== '') {
        const month = moment(props.history[element].time).format('YYYY-MM')
        if (isEmpty(months[month])) {
          months[month] = { total: 0, ...series }
        }
        months[month]['total'] += 1
        months[month][`rate${satisfaction}`] += 1
      }
    }
    const percentages = Object.keys(series).map((k) => ({
      name: `Rating ${k.replace('rate', '')}`,
    }))
    const monthKeys = Object.keys(months)
    // console.log(months)
    for (let index = 0; index < Object.keys(series).length; index++) {
      const serie = Object.keys(series)[index]
      percentages[index].data = monthKeys.map((m) => {
        return months[m][serie] // / months[m]['total']) * 100
      })
    }
    // setChartData(percentages)
    // console.log(percentages)

    setSeries(percentages)

    setChart((prevchart) => ({
      ...prevchart,
      xaxis: {
        categories: monthKeys,
      },
    }))
  }, [props.history, props.historyKeys, props.userFilter])

  return (
    <>
      <div className="pv4">Chart:</div>
      <div className="w-100">
        {series.length === 0 ? (
          'No rating feedback yet received, charts will display as soon as customers start to leave ratings'
        ) : (
          <Chart options={chart} series={series} type="bar" />
        )}
      </div>
    </>
  )
}
