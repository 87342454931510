import React, { useState, useEffect } from 'react'

import SignIn from './components/signIn'
import Feedback from './components/feedback'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { firebaseConfig } from './firebase/firebase'
import UserSelect from './components/userSelect'

const isEmpty = (a) => {
  return a === null || a === undefined
}

const App = () => {
  const [fbLoaded, setFbLoaded] = useState(false)
  const [usermap, setUsermap] = useState('')
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState({})
  const [user, setUser] = useState({})
  const [subusers, setSubusers] = useState({})
  const [uFired, setUFired] = useState(false)
  const [watchedUsers, setWatchedUsers] = useState([])
  const [userChooser, setUserChooser] = useState(null)

  useEffect(() => {
    console.log('loading fb')
    if (!fbLoaded) {
      console.log('init fb')
      firebase.initializeApp(firebaseConfig)
      setFbLoaded(true)
    }
    if (fbLoaded) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUser(user)
        } else {
          setUser(null)
          setUsermap('')
        }
      })
    }
  }, [fbLoaded])

  useEffect(() => {
    const loadSubuser = (uid) => {
      return new Promise((res, rej) => {
        console.log(uid)
        firebase
          .database()
          .ref(`users/${uid}`)
          .once('value')
          .then((snapshot) => {
            // console.log(snapshot.val())
            res({ [uid]: snapshot.val() })
          })
          .catch((err) => {
            console.log(err)
            rej(null)
          })
      })
    }

    if (
      user !== null &&
      !isEmpty(user.uid) &&
      usermap === '' &&
      uFired === false
    ) {
      setUFired(true)
      // usermap is where one uid (api)
      // is mapped to another (auth)
      firebase
        .database()
        .ref(`usermap/${user.uid}`)
        .once('value')
        .then((snapshot) => {
          if (isEmpty(snapshot.val())) {
            // no mapped user, so it is the user itself
            setUsermap(snapshot.key)
          } else {
            setUsermap(snapshot.val().uid)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      // some users have subusers
      firebase
        .database()
        .ref(`users/${user.uid}/subusers`)
        .once('value')
        .then((snapshot) => {
          const subuserlist = snapshot.val()
          const sukeys = Object.keys(subuserlist)
          Promise.all(sukeys.map(loadSubuser)).then((s) => {
            // console.log(s)
            setSubusers(s)
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [user, usermap, uFired, subusers])

  useEffect(() => {
    if (usermap !== '' && !loading) {
      setLoading(true)
      firebase
        .database()
        .ref(`history/${usermap}`)
        .orderByChild('revtime')
        .limitToFirst(300)
        .on('child_added', (data) => {
          postHistory(data, usermap)
        })
      firebase
        .database()
        .ref(`history/${usermap}`)
        .on('child_changed', (data) => {
          postHistory(data, usermap)
        })
    }
  }, [usermap, loading])

  useEffect(() => {
    console.log(subusers)
  }, [subusers])

  const loadSubuser = (su) => {
    if (
      su.value.uid !== usermap &&
      watchedUsers.findIndex((u) => u === su.value.uid) === -1
    ) {
      console.log(su.value.uid)
      const wu = JSON.parse(JSON.stringify(watchedUsers))
      wu.push(su.value.uid)
      setWatchedUsers(wu)
      firebase
        .database()
        .ref(`history/${su.value.uid}`)
        .orderByChild('revtime')
        .limitToFirst(300)
        .on('child_added', (data) => {
          postHistory(data, su.value.uid)
        })

      firebase
        .database()
        .ref(`history/${su.value.uid}`)
        .on('child_changed', (data) => {
          postHistory(data, su.value.uid)
        })
    }
    setUserChooser(su.value.uid)
  }

  /**
   * Interface for storing the particular user's history
   */
  const postHistory = (data, uid) => {
    // console.log(data.key, data.val())
    const onehist = {
      customer: data.val().customer,
      phone: data.val().phone,
      email: data.val().email,
      revtime: data.val().revtime,
      time: data.val().time,
      guifeedback: data.val().guifeedback,
      feedback: data.val().feedback,
      user: uid,
    }
    
    setHistory((prevHistory) => ({
      ...prevHistory,
      [data.key]: onehist,
    }))
  }

  /**
   * Signing in with password & email
   */
  const signIn = (form) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(form.email, form.password)
      .then((si) => {
        console.log('signed in')
      })
      .catch((err) => {
        console.log(err)
        alert('Incorrect credentials, not able to sign in')
      })
  }

  /**
   * Default workflow is a random long pw is generated
   * So new users would reset their pw on the first login
   */
  const resetPw = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((si) => {
        alert('Password reset email sent')
      })
      .catch((err) => {
        if (err.code === 'auth/user-not-found') {
          alert('No such email exists')
        } else {
          alert('Could not reset password')
        }
      })
  }

  return (
    <div className="avenir w-100 h-100 rw-grid grid smallminheight smallminheight-ns">
      <div className="gcs1 gce2 grs1 gre2 pa2 bg-misty-blue-mid white shadow-2 fixed top-0 right-0 left-0 z-5">
        <div className="mw7 center grid header-grid">
          <div className="gcs1 gce2 grs1 gre2">RemindWorks</div>
          {user === null ? null : (
            <div
              className="gcs2 gce3 grs1 gre2 pointer dim tr"
              onClick={(e) => {
                firebase.auth().signOut()
              }}
            >
              Sign Out
            </div>
          )}
        </div>
      </div>
      <div
        className={
          'gcs1 gce2 grs2 gre3 center w-100 ' + (user === null ? 'mw7' : 'mw7')
        }
      >
        {user === null ? (
          <SignIn
            submitForm={(form) => signIn(form)}
            resetPw={(email) => resetPw(email)}
          />
        ) : (
          <div className="grid gafr">
            {JSON.stringify(subusers) === '{}' ? null : (
              <UserSelect
                subusers={subusers}
                branchSelect={(su) => {
                  loadSubuser(su)
                }}
              />
            )}
            <Feedback history={history} userFilter={userChooser || usermap} />
          </div>
        )}
      </div>
    </div>
  )
}

export default App
