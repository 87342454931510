import React, { Fragment, useState } from 'react'

export default function SignIn(props) {
  const [showReset, setShowReset] = useState(false)
  const [resetMessage, setResetMessage] = useState('Reset Password')

  const [form, setForm] = useState({
    email: {
      key: 'email',
      label: 'Email',
      required: true,
      description: 'Please provide your account email',
      value: '',
      type: 'email',
    },
    password: {
      key: 'password',
      label: 'Password',
      required: true,
      description: 'Please provide your account password',
      value: '',
      type: 'password',
    },
  })
  const [question, setQuestion] = useState(0)

  const setText = (key, event) => {
    const prevF = JSON.parse(JSON.stringify(form))
    prevF[key].value = event.target.value
    setForm(prevF)
  }

  return (
    <>
      <div className="w-100 h-100">
        <div className="grid form-grid h-100">
          {showReset ? (
            <div className="ph4 grs1 gre2 self-center">
              <span className="fw5 w-100 misty-blue-mid">
                Password reset coming soon
              </span>
            </div>
          ) : (
            [form[Object.keys(form)[question]]].map((q, i) => (
              <Fragment key={i}>
                <div className="ph4 grs1 gre2 self-center">
                  <form
                    className="w-100 h-100 mt2"
                    onSubmit={(e) => {
                      e.preventDefault()
                      if (question < Object.keys(form).length - 1) {
                        console.log('next q')
                        setQuestion(question + 1)
                      } else {
                        console.log(form)
                        const formout = {
                          email: form.email.value,
                          password: form.password.value,
                        }
                        props.submitForm(formout)
                      }
                    }}
                  >
                    {q.type === 'star' ? (
                      <div className="w-100 pb3 fw5">
                        {q.label}
                        {q.required ? '*' : ''}
                      </div>
                    ) : null}
                    <label>
                      {q.description}
                      <input
                        className="mv4 w-100 pa2-ns"
                        type={q.type}
                        required={q.required}
                        placeholder={`${q.label}${q.required ? '*' : ''}`}
                        name={Object.keys(form)[question]}
                        value={q.value}
                        onChange={(event) => setText(q.key, event)}
                      />
                    </label>
                    <input
                      type="submit"
                      className="bg-misty-blue-mid white br2 bn pv2 ph4 dim pointer center db shadow-2"
                      value={
                        question < Object.keys(form).length - 1
                          ? 'Enter'
                          : 'Sign In'
                      }
                    />
                  </form>
                </div>
                <div className="grid ph4 grs2 gre3 pt3">
                  <button
                    disabled={form.email.value === ''}
                    onClick={(e) => {
                      if (form.email.value !== '') {
                        if (resetMessage === 'Reset Password') {
                          setResetMessage('Really reset?')
                          setTimeout(() => {
                            setResetMessage('Reset Password')
                          }, 3000)
                        } else {
                          // really resetting
                          props.resetPw(form.email.value)
                        }
                      }
                    }}
                    className={
                      ' white br2 bn pa2 ml2 shadow-2'
                      + (form.email.value === '' ? ' bg-moss-green-dark' : ' dim pointer bg-misty-blue-mid')
                    }
                  >
                    {resetMessage}
                  </button>
                </div>
              </Fragment>
            ))
          )}
        </div>
      </div>
    </>
  )
}
