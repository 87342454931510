import React, { useState, useEffect } from 'react'
import Select from 'react-select'

export default function UserSelect(props) {
  const [userList, setUserList] = useState([])

  useEffect(() => {
    setUserList(
      props.subusers.map((s) => {
        const uid = Object.keys(s)[0]
        return {
          value: s[uid],
          label: `${s[uid].short} (${s[uid].details})`,
        }
      })
    )
  }, [props])

  const pickBranch = (b) => {
    // console.log(b)
    props.branchSelect(b)
  }

  return (
    <form className="w-100 sticky top-feedback z-2 pt4">
      <Select
        placeholder="Select branch..."
        options={userList}
        onChange={pickBranch}
      />
    </form>
  )
}
