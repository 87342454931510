import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import Charts from './charts'

const isEmpty = (a) => {
  return a === undefined || a === null
}

export default function Feedback(props) {
  const stars = [0, 1, 2, 3, 4]

  const [histKeys, setHistKeys] = useState([])

  useEffect(() => {
    // console.log(Object.keys(props.history).length)
    setHistKeys(Object.keys(props.history))
  }, [props.history])

  const [hoverMessage, setHoverMessage] = useState('')
  const [ratingFilter, setRatingFilter] = useState('All')
  const [ratingOperator, setRatingOperator] = useState('=')

  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
      if (msg === 'successful') {
        successCopy()
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(
      () => {
        successCopy()
      },
      (err) => {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }

  const successCopy = () => {
    setHoverMessage('Copied to clipboard!')
    setTimeout(() => {
      setHoverMessage('')
    }, 2000)
  }

  return (
    <>
      <div className="w-100 h-100">
        {/* <div className="w-100 h-100">{histKeys.length}</div> */}
        <div className="grid feedback-grid sticky misty-blue-mid top-feedback bg-white pv2 nt4 h4 z-1 content-end">
          <div className="gcs1 gce2 ph2 self-end">Date</div>
          <div className="gcs2 gce3 ph2 self-end">Customer</div>
          <div className="gcs3 gce4 ph2 self-end">Contact</div>
          <div className="gcs4 gce5 ph2 self-end">
            {['All', -1, ...stars].map((star, ix) => {
              const realRating = star === 'All' ? 'All' : star + 1
              const operator = realRating === ratingFilter ? ratingOperator : ''
              return (
                <span
                  key={`opt${ix}`}
                  className="pr2 pointer dim"
                  onClick={(e) => {
                    if (realRating === 'All') setRatingOperator('=')
                    if (ratingFilter === realRating && realRating !== 'All') {
                      // changing the operator
                      if (operator === '=') {
                        setRatingOperator('<=')
                      } else if (operator === '<=') {
                        setRatingOperator('>=')
                      } else {
                        setRatingOperator('=')
                      }
                    } else {
                      setRatingFilter(realRating)
                      setRatingOperator('=')
                    }
                  }}
                >
                  {ratingOperator === '=' && realRating === 'All'
                    ? ''
                    : operator}
                  {realRating}
                </span>
              )
            })}
            <div className="w-100 pt2">Rating</div>
          </div>
          <div className="gcs5 gce6 ph2 self-end">Feedback</div>
        </div>
        <div className="grid feedback-grid">
          {histKeys.map((key, ih) => {
            const odd = ih % 2 === 0 // 0 is the first row, i.e. odd
            const oddbg = odd ? ' bg-dusty-grey-light' : ''
            const onehist = props.history[key]
            const satisfaction =
              isEmpty(onehist.guifeedback) ||
              onehist.guifeedback.satisfaction === ''
                ? null
                : parseInt(onehist.guifeedback.satisfaction)
            let feedback =
              isEmpty(onehist.guifeedback) && isEmpty(onehist.feedback)
                ? null
                : (isEmpty(onehist.guifeedback) ||
                  isEmpty(onehist.guifeedback.commentsAndCompliments)
                    ? ''
                    : onehist.guifeedback.commentsAndCompliments) +
                  (isEmpty(onehist.feedback)
                    ? ''
                    : (isEmpty(onehist.guifeedback) ? '' : '\n\nsms: ') +
                      onehist.feedback)
            if (feedback === '') {
              feedback = null
            }
            const satisfactionNum = isEmpty(satisfaction)
              ? ratingOperator === '='
                ? -1
                : ratingOperator === '>='
                ? -1
                : 99
              : satisfaction
            const inFilter =
              (ratingFilter === 'All' ||
                (parseInt(ratingFilter) === satisfactionNum &&
                  ratingOperator === '=') ||
                (parseInt(ratingFilter) <= satisfactionNum &&
                  ratingOperator === '>=') ||
                (parseInt(ratingFilter) >= satisfactionNum &&
                  ratingOperator === '<=')) &&
              onehist.user === props.userFilter
            if (!inFilter) {
              return null
            }

            return (
              <Fragment key={ih}>
                <div className={'gcs1 gce2 pa2' + oddbg}>
                  {moment(onehist.time).format('YYYY-MM-DD')}
                </div>
                <div className={'gcs2 gce3 pa2' + oddbg}>
                  {onehist.customer}
                </div>
                <div className={'gcs3 gce4 pa2' + oddbg}>
                  {onehist.phone === '' ? (
                    ''
                  ) : (
                    <span
                      className="pr2 grow dim pointer"
                      onMouseEnter={(e) => setHoverMessage(onehist.phone)}
                      onMouseLeave={(e) => setHoverMessage('')}
                      onClick={(e) => copyTextToClipboard(onehist.phone)}
                    >
                      #
                    </span>
                  )}
                  {onehist.email === '' ? (
                    ''
                  ) : (
                    <span
                      className="grow dim pointer"
                      onMouseEnter={(e) => setHoverMessage(onehist.email)}
                      onMouseLeave={(e) => setHoverMessage('')}
                      onClick={(e) => copyTextToClipboard(onehist.email)}
                    >
                      @
                    </span>
                  )}
                </div>
                <div className={'gcs4 gce5 gc5 grid pa2' + oddbg}>
                  {isEmpty(satisfaction)
                    ? null
                    : stars.map((s) => (
                        <div
                          key={'s' + s}
                          className={`gcs${s + 1} gce${
                            s + 2
                          } f3 center misty-blue-mid`}
                        >
                          {satisfaction >= s + 1 ? '★' : '☆'}
                        </div>
                      ))}
                </div>
                <div
                  className={'gcs5 gce6 dim pa2' + oddbg}
                  onMouseEnter={(e) => {
                    setHoverMessage(isEmpty(feedback) ? '' : feedback)
                  }}
                  onMouseLeave={(e) => setHoverMessage('')}
                >
                  {isEmpty(feedback) ? '' : 'Feedback...'}
                </div>
              </Fragment>
            )
          })}
        </div>
        {histKeys.length === 0 ? null : (
          <Charts
            history={props.history}
            historyKeys={histKeys}
            userFilter={props.userFilter}
          />
        )}
      </div>

      {hoverMessage === '' ? null : (
        <div className="fixed bottom-0 w-100 left-0 right-0 pb2">
          <div className="center mw7 pv2 ph3 bg-misty-blue-light dt br2 pre">
            {hoverMessage}
          </div>
        </div>
      )}
    </>
  )
}
